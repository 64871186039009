<template>
  <form v-if="form" class="space-y-6 w-full">

    <div class="grid grid-cols-1 sm:grid-cols-2 gap-6 pt-4">
      <template v-if="mainCustomer && this.order.status.id < 3">
        <div class="col-span-1">
          <Select v-model="form.branch" :errors="errors.branch" :items="form.customer.branches" placeholder="Klienta filiāle" />
        </div>

          <div class="col-span-1">
            <Input type="date" name="order_date" placeholder="Datums" v-model="form.order_date" :errors="errors.order_date" />
          </div>

          <div class="col-span-1">
            <Checkbox class="md:mt-8" text="Reversais PVN" v-model="form.reverse_vat"/>
          </div>

      </template>

      <div class="col-span-1">
        <Autocomplete placeholder="Valsts" v-model="form.country" :errors="errors.country_id"
                      :items="countries" :showItems="true" :cleanable="false" />
      </div>

        <div class="col-span-1">
            <Radio label="Valoda:" name="lang" v-model="form.lang"
                   :items="[
                  { name: 'LV', value: 'lv' },
                  { name: 'ENG', value: 'en' },
                ]"
                   :errors="errors.lang"
            />
        </div>

      <div class="col-span-2">
        <Textarea placeholder="Piezīmes" v-model="form.notes" :errors="errors.notes" />
      </div>

      <div class="col-span-2 pt-6 pb-3">
          <Textarea placeholder="Iekšējās piezīmes" v-model="form.internal_notes" :errors="errors.internal_notes" />
      </div>

    </div>

    <div class="pt-2">
      <div class="flex justify-end">
        <button type="button" class="bg-white dark:bg-gray-750 py-2 px-4 border border-gray-300 dark:border-gray-500 rounded-md shadow-sm text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-0 mr-2" @click="hideEditItemForm">
          Atcelt
        </button>

        <template v-if="!loading">
          <button type="button" class="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-button2-from to-button2-to hover:from-button2-to hover:to-button2-from focus:outline-none focus:border-none transition duration-500 ease-in-out" @click.prevent="submit">
            Saglabāt
          </button>
        </template>
        <template v-else>
          <Loading />
        </template>
      </div>
    </div>
  </form>
</template>

<script>

import { mapGetters } from "vuex";
import Validation from "@/modules/Validation";
import Loading from "@/components/Components/Loading";
import Select from "@/components/Components/Select";
import Textarea from "@/components/Components/Textarea";
import Input from "@/components/Components/Input";
import Checkbox from "@/components/Components/Checkbox";
import Autocomplete from "@/components/Components/Autocomplete";
import Radio from "@/components/Components/Radio";

export default {
  name: "EditOrderDetails",
  components: {
    Loading,
    Select,
    Textarea,
    Input,
    Checkbox,
    Autocomplete,
      Radio
  },
  data: () => ({
    form: null,
    languages: ['en', 'lv']
  }),
  props: {
    order: {
      type: Object,
      required: true,
    }
  },
  created() {
    if (!this.countries) {
      this.$store.dispatch('fetchCountries');
    }
  },
  mounted() {
    this.form = this.order
  },
  computed: {
    ...mapGetters({
      mainCustomer: "systemMainCustomer",
      countries: "fetchedCountries",
      loading: "loading",
      errors: "errors",
    }),
    formValidation() {
      return {
        managing_branch: {
          rules: ['required']
        }
      }
    }
  },
  methods: {
    hideEditItemForm() {
      this.$store.dispatch("removeAllFormsForDisplay");
    },
    submit() {
      this.$Progress.start();
      if (Validation(this.formValidation, this.form)) {
        this.$store.dispatch('updateOrder', {
          id: this.order.id,
          data: {
            notes: this.order.notes,
            internal_notes: this.order.internal_notes,
            order_date: this.form.order_date,
            reverse_vat: this.form.reverse_vat,
            branch_id: this.order.branch ? this.order.branch.id : null,
            country_id: this.form.country ? this.form.country.id : null,
            lang: this.form.lang ,
          }
        })
      } else {
        this.$Progress.fail();
      }
    },
  },
}
</script>

<style>

</style>